import React from "react";

export default function RelojSvg() {
  return (
    <div className="w-10 h-10 hover:scale-125">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
      >
        <g>
          <circle fill="#FFFFFF" cx="21" cy="21" r="18" />
          <path
            fill="#43A6DD"
            d="M21,41C9.97217,41,1,32.02783,1,21S9.97217,1,21,1s20,8.97217,20,20S32.02783,41,21,41z M21,5
	C12.17773,5,5,12.17773,5,21s7.17773,16,16,16s16-7.17773,16-16S29.82227,5,21,5z"
          />
          <path
            fill="#E86C60"
            d="M14,29c-0.25586,0-0.51172-0.09766-0.70703-0.29297c-0.39062-0.39062-0.39062-1.02344,0-1.41406l7-7
	c0.39062-0.39062,1.02344-0.39062,1.41406,0s0.39062,1.02344,0,1.41406l-7,7C14.51172,28.90234,14.25586,29,14,29z"
          />
          <path
            fill="#223E49"
            d="M21,22c-0.55273,0-1-0.44775-1-1V11c0-0.55225,0.44727-1,1-1s1,0.44775,1,1v10
	C22,21.55225,21.55273,22,21,22z"
          />
          <path
            fill="#223E49"
            d="M31,22H21c-0.55273,0-1-0.44775-1-1s0.44727-1,1-1h10c0.55273,0,1,0.44775,1,1S31.55273,22,31,22z"
          />
          <circle fill="#223E49" cx="21" cy="21" r="3" />
          <path
            opacity="0.1"
            d="M41,21c0-2.3313-0.42169-4.56091-1.15826-6.64313C38.63318,14.12219,37.31573,14,36,14
	c-4.47266,0-9,1.37402-9,4v3c0,0.35736,0.09039,0.68933,0.24652,1C27.09039,22.31067,27,22.64264,27,23v3
	c0,0.35736,0.09039,0.68933,0.24652,1C27.09039,27.31067,27,27.64264,27,28v3c0,0.35736,0.09039,0.68933,0.24652,1
	C27.09039,32.31067,27,32.64264,27,33v3c0,0.35736,0.09039,0.68933,0.24652,1C27.09039,37.31067,27,37.64264,27,38v2h0.21155
	C35.20544,37.37946,41,29.85852,41,21z"
          />
          <path
            fill="#D8BC48"
            d="M46,39H30c-0.55225,0-1,0.44775-1,1v3c0,2.62598,4.52734,4,9,4s9-1.37402,9-4v-3
	C47,39.44775,46.55225,39,46,39z"
          />
          <path
            fill="#EFD358"
            d="M38,36c-4.47266,0-9,1.37402-9,4s4.52734,4,9,4s9-1.37402,9-4S42.47266,36,38,36z"
          />
          <path
            fill="#D8BC48"
            d="M46,34H30c-0.55225,0-1,0.44775-1,1v3c0,2.62598,4.52734,4,9,4s9-1.37402,9-4v-3
	C47,34.44775,46.55225,34,46,34z"
          />
          <path
            fill="#EFD358"
            d="M38,31c-4.47266,0-9,1.37402-9,4s4.52734,4,9,4s9-1.37402,9-4S42.47266,31,38,31z"
          />
          <path
            fill="#D8BC48"
            d="M46,29H30c-0.55225,0-1,0.44775-1,1v3c0,2.62598,4.52734,4,9,4s9-1.37402,9-4v-3
	C47,29.44775,46.55225,29,46,29z"
          />
          <path
            fill="#EFD358"
            d="M38,26c-4.47266,0-9,1.37402-9,4s4.52734,4,9,4s9-1.37402,9-4S42.47266,26,38,26z"
          />
          <path
            fill="#D8BC48"
            d="M46,24H30c-0.55225,0-1,0.44775-1,1v3c0,2.62598,4.52734,4,9,4s9-1.37402,9-4v-3
	C47,24.44775,46.55225,24,46,24z"
          />
          <path
            fill="#EFD358"
            d="M38,21c-4.47266,0-9,1.37402-9,4s4.52734,4,9,4s9-1.37402,9-4S42.47266,21,38,21z"
          />
          <path
            fill="#D8BC48"
            d="M46,19H30c-0.55225,0-1,0.44775-1,1v3c0,2.62598,4.52734,4,9,4s9-1.37402,9-4v-3
	C47,19.44775,46.55225,19,46,19z"
          />
          <path
            fill="#EFD358"
            d="M38,16c-4.47266,0-9,1.37402-9,4s4.52734,4,9,4s9-1.37402,9-4S42.47266,16,38,16z"
          />
        </g>
      </svg>
    </div>
  );
}
