import React from "react";

export default function CelularSvg() {
  return (
    <div className="w-10 h-10 hover:scale-125">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 128 128"
      >
        <path
          fill="#72B6D3"
          d="M61 124H25.9c-8.3 0-15-6.7-15-15V19c0-8.3 6.7-15 15-15H61c8.3 0 15 6.7 15 15v90c0 8.3-6.7 15-15 15z"
        ></path>
        <path
          fill="#62355C"
          d="M61 125.2H25.9c-9 0-16.2-7.3-16.2-16.2V19c0-9 7.3-16.2 16.2-16.2H61c9 0 16.2 7.3 16.2 16.2v90c0 9-7.3 16.2-16.2 16.2zM25.9 5.2c-7.6 0-13.8 6.2-13.8 13.8v90c0 7.6 6.2 13.8 13.8 13.8H61c7.6 0 13.8-6.2 13.8-13.8V19c0-7.6-6.2-13.8-13.8-13.8H25.9z"
        ></path>
        <path
          fill="#FFF"
          d="M18.9 22.8H68c1.6 0 2.9 1.3 2.9 2.9v76.6c0 1.6-1.3 2.9-2.9 2.9H18.9c-1.6 0-2.9-1.3-2.9-2.9V25.7c0-1.6 1.3-2.9 2.9-2.9z"
        ></path>
        <path
          fill="#593055"
          d="M76 43.9H49.7c-6 0-10.9 4.9-10.9 10.9V93c0 6 4.9 10.9 10.9 10.9H76v-60z"
          opacity="0.2"
        ></path>
        <path
          fill="#62355C"
          d="M68 106.5H18.9c-2.3 0-4.1-1.9-4.1-4.1V25.7c0-2.3 1.9-4.1 4.1-4.1H68c2.3 0 4.1 1.9 4.1 4.1v76.6c.1 2.3-1.8 4.2-4.1 4.2zM18.9 24.1c-.9 0-1.6.7-1.6 1.6v76.6c0 .9.7 1.6 1.6 1.6H68c.9 0 1.6-.7 1.6-1.6V25.7c0-.9-.7-1.6-1.6-1.6H18.9z"
        ></path>
        <circle
          cx="43.5"
          cy="114.5"
          r="5.1"
          fill="#593055"
          opacity="0.2"
        ></circle>
        <path
          fill="#62355C"
          d="M43.5 120.8c-3.5 0-6.3-2.8-6.3-6.3s2.8-6.3 6.3-6.3 6.3 2.8 6.3 6.3-2.9 6.3-6.3 6.3zm0-10.1c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8z"
        ></path>
        <circle cx="35" cy="13.4" r="1.5" fill="#62355C"></circle>
        <path
          fill="#62355C"
          d="M52.7 14.7H40.1c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h12.6c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2z"
        ></path>
        <path
          fill="#FFF"
          d="M106.2 94.9H40.7c-6 0-10.9-4.9-10.9-10.9V45.8c0-6 4.9-10.9 10.9-10.9h65.5c6 0 10.9 4.9 10.9 10.9V84c0 6-4.9 10.9-10.9 10.9z"
        ></path>
        <path fill="#FFF" d="M117.1 65.4L117.1 55.6 29.8 55.6 29.8 65.4"></path>
        <path
          fill="#938993"
          d="M117.1 55.6L117.1 45.8 29.8 45.8 29.8 55.6 117.1 55.6"
        ></path>
        <path
          fill="#62355C"
          d="M117.1 66.7H29.8c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h87.3c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2zM117.1 56.9H29.8c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h87.3c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2z"
        ></path>
        <circle cx="102.9" cy="79.6" r="7.5" fill="#FEE444"></circle>
        <path
          fill="#62355C"
          d="M102.9 88.4c-4.8 0-8.8-3.9-8.8-8.8s3.9-8.8 8.8-8.8 8.8 3.9 8.8 8.8-4 8.8-8.8 8.8zm0-15c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2 6.2-2.8 6.2-6.2-2.8-6.2-6.2-6.2z"
        ></path>
        <circle cx="90.9" cy="79.6" r="7.5" fill="#F37865"></circle>
        <path
          fill="#62355C"
          d="M90.9 88.4c-4.8 0-8.8-3.9-8.8-8.8s3.9-8.8 8.8-8.8 8.8 3.9 8.8 8.8-4 8.8-8.8 8.8zm0-15c-3.4 0-6.2 2.8-6.2 6.2s2.8 6.2 6.2 6.2 6.2-2.8 6.2-6.2-2.8-6.2-6.2-6.2z"
        ></path>
        <path
          fill="#62355C"
          d="M106.2 96.1H40.7c-6.7 0-12.2-5.5-12.2-12.2V45.8c0-6.7 5.5-12.2 12.2-12.2h65.5c6.7 0 12.2 5.5 12.2 12.2V84c-.1 6.7-5.5 12.1-12.2 12.1zm-65.5-60c-5.3 0-9.7 4.3-9.7 9.7V84c0 5.3 4.3 9.7 9.7 9.7h65.5c5.3 0 9.7-4.3 9.7-9.7V45.8c0-5.3-4.3-9.7-9.7-9.7H40.7z"
        ></path>
        <path
          fill="#62355C"
          d="M75.8 61.8H35.4c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h40.4c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2zM98.4 61.8H87.5c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h10.9c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2zM69.1 75.4H42.9c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h26.2c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2zM69.1 80.9H42.9c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h26.2c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2zM69.1 86.3H42.9c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h26.2c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2zM117.1 56.9H29.8c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h87.3c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2zM117.1 47.1H29.8c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h87.3c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2z"
        ></path>
      </svg>
    </div>
  );
}
