import Molde from "./MoldeBeneficio";
import CardInfo from "../common/card/CardInfoMiCumpa";
import InfoItems from "./infoItems";

export default function MiCumpa() {
  return (
    <>
      <Molde
        image={"/images/micumpa.jpg"}
        title={"Mi CUMPA"}
        description={
          "MI CUMPA te da la posibilidad de agendar citas con nuestros especialistas en temas médicos, legales, contables y mucho más."
        }
        textButton={"Agendar una cita"}
        linkForm={"https://wa.link/xwp3fj"}

        principal={
          <InfoItems
            Title={""}
            textColor={"text-primary-800"}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8 sm:mx-0 mx-0 md:mx-20 lg:mx-32 sm:mx-32">
              <CardInfo
                icon={"/icons/micumpa_icono.svg"}
                title={"¿Qué es MI CUMPA?"}
                description={"Es un canal de consultas digitales de servicios profesionales para nuestros más de 20 mil socios de la cooperativa DILE. Mi Cumpa te da la posibilidad de agendar citas con nuestros especialistas en temas médicos, legales, contables y muchos más."}
              />
              <CardInfo
                icon={"/icons/beneficios.svg"}
                title={"¿Cómo ser parte de MI CUMPA?"}
                description={"Debe ser socio de la cooperativa DILE, es decir tener un crédito o un cuenta de ahorros con nosotros y automáticamente puedes agendar una cita para cualquiera de nuestros servicios profesionales."}
              />
            </div>
          </InfoItems>
        }
      />
    </>
  );
}
