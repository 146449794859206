import React from "react";

export default function MasInclusivoSvg() {
  return (
    <div className="w-10 h-10 hover:scale-125">
      <svg
        enableBackground="new 0 0 64 64"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 64 64"
        width="100%"
        height="100%"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g id="loan_money_1_">
          <g>
            <g>
              <rect fill="#FFD740" height="4" width="20" x="27" y="43" />
              <rect fill="#FFE57F" height="4" width="9" x="27" y="43" />
              <rect fill="#FFC400" height="1" width="20" x="27" y="46" />
              <rect fill="#FFFFFF" height="3" width="1" x="28" y="43" />
              <rect fill="#FFC400" height="3" width="1" x="40" y="43" />
              <rect fill="#FFC400" height="3" width="1" x="42" y="43" />
              <rect fill="#FFC400" height="3" width="1" x="44" y="43" />
              <rect fill="#FFC400" height="3" width="1" x="46" y="43" />
            </g>
            <g>
              <rect fill="#FFD740" height="4" width="20" x="26" y="39" />
              <rect fill="#FFE57F" height="4" width="9" x="26" y="39" />
              <rect fill="#FFC400" height="1" width="20" x="26" y="42" />
              <rect fill="#FFFFFF" height="3" width="1" x="27" y="39" />
              <rect fill="#FFC400" height="3" width="1" x="39" y="39" />
              <rect fill="#FFC400" height="3" width="1" x="41" y="39" />
              <rect fill="#FFC400" height="3" width="1" x="43" y="39" />
              <rect fill="#FFC400" height="3" width="1" x="45" y="39" />
            </g>
            <g>
              <g>
                <path
                  d="M30.229,23.695c-3.703-3.704-3.703-9.73,0-13.436c3.705-3.703,9.731-3.703,13.436,0      c3.704,3.705,3.704,9.731,0,13.436S33.934,27.399,30.229,23.695z"
                  fill="#FFD740"
                />
                <path
                  d="M37,8c4.963,0,9,4.037,9,9s-4.037,9-9,9s-9-4.037-9-9S32.037,8,37,8 M37,7c-5.523,0-10,4.478-10,10      s4.477,10,10,10s10-4.478,10-10S42.523,7,37,7L37,7z"
                  fill="#FDD835"
                />
              </g>
              <path
                d="M29.188,22.326c-1.566-3.646-0.876-8.027,2.101-11.006c3.133-3.132,7.82-3.736,11.568-1.84     c0-0.107,0.002-0.213,0.008-0.319c-3.852-2.358-8.949-1.882-12.283,1.452c-3.184,3.184-3.77,7.98-1.762,11.76     C28.942,22.354,29.064,22.336,29.188,22.326z"
                fill="#FFFFFF"
              />
              <g>
                <path
                  d="M37,24.5c-4.136,0-7.5-3.364-7.5-7.5s3.364-7.5,7.5-7.5s7.5,3.364,7.5,7.5S41.136,24.5,37,24.5z"
                  fill="#FFE57F"
                />
                <path
                  d="M37,10c3.859,0,7,3.141,7,7s-3.141,7-7,7s-7-3.141-7-7S33.141,10,37,10 M37,9c-4.418,0-8,3.584-8,8      s3.582,8,8,8s8-3.584,8-8S41.418,9,37,9L37,9z"
                  fill="#FFC400"
                />
              </g>
              <g>
                <path
                  d="M37,14c0.719,0,1,0.521,1,1h2c0-0.814-0.674-1.619-2-1.894V12h-2v1.092c-1.326,0.24-2,1.009-2,2.408      c0,1.313,1.031,2.5,3,2.5c0.577,0,1,0.438,1,1c0,0.595-0.334,1-1,1s-1-0.367-1-1h-2c0,1.245,0.684,1.78,2,1.94V22h2v-1.075      c1.326-0.2,2.025-0.874,2-2.425c0-1.375-1-2.499-3-2.499c-0.563,0-1-0.313-1-1.001C36,14.482,36.281,14,37,14z"
                  fill="#FFAB00"
                />
              </g>
              <path
                d="M37,8c4.963,0,9,4.037,9,9s-4.037,9-9,9s-9-4.037-9-9S32.037,8,37,8 M37,7c-5.523,0-10,4.478-10,10     s4.477,10,10,10s10-4.478,10-10S42.523,7,37,7L37,7z"
                fill="#FFC400"
              />
            </g>
            <g>
              <rect fill="#FFD740" height="4" width="20" x="27" y="27" />
              <rect fill="#FFE57F" height="4" width="9" x="27" y="27" />
              <rect fill="#FFC400" height="1" width="20" x="27" y="30" />
              <rect fill="#FFFFFF" height="3" width="1" x="28" y="27" />
              <rect fill="#FFC400" height="3" width="1" x="40" y="27" />
              <rect fill="#FFC400" height="3" width="1" x="42" y="27" />
              <rect fill="#FFC400" height="3" width="1" x="44" y="27" />
              <rect fill="#FFC400" height="3" width="1" x="46" y="27" />
            </g>
            <g>
              <rect fill="#FFD740" height="4" width="20" x="26" y="31" />
              <rect fill="#FFE57F" height="4" width="9" x="26" y="31" />
              <rect fill="#FFC400" height="1" width="20" x="26" y="34" />
              <rect fill="#FFFFFF" height="3" width="1" x="27" y="31" />
              <rect fill="#FFC400" height="3" width="1" x="39" y="31" />
              <rect fill="#FFC400" height="3" width="1" x="41" y="31" />
              <rect fill="#FFC400" height="3" width="1" x="43" y="31" />
              <rect fill="#FFC400" height="3" width="1" x="45" y="31" />
            </g>
            <g>
              <rect fill="#FFD740" height="4" width="20" x="27" y="35" />
              <rect fill="#FFE57F" height="4" width="9" x="27" y="35" />
              <rect fill="#FFC400" height="1" width="20" x="27" y="38" />
              <rect fill="#FFFFFF" height="3" width="1" x="28" y="35" />
              <rect fill="#FFC400" height="3" width="1" x="40" y="35" />
              <rect fill="#FFC400" height="3" width="1" x="42" y="35" />
              <rect fill="#FFC400" height="3" width="1" x="44" y="35" />
              <rect fill="#FFC400" height="3" width="1" x="46" y="35" />
            </g>
          </g>
          <path
            d="M46,44.943c-0.029,0.008-0.062,0.016-0.062,0.016L44.944,45c0,0-1.348-5-7.007-5h-6.955   L29,39h-2v4h2v3v1h9h8V44.943z"
            fill="#37474F"
            opacity="0.1"
          />
          <path
            d="M25.938,47H44c0-1,0-6-7.063-6h-11c-1.656,0-3,1.344-3,3S24.281,47,25.938,47z"
            fill="#F4D0A1"
          />
          <g>
            <path
              d="M43.959,46.166C41.514,46.659,39,47,39,47h5C44,46.827,43.996,46.531,43.959,46.166z"
              fill="#F4D0A1"
            />
            <path
              d="M55,40c-3.092,1.855-5,4-7,5.027c-0.64,0.328-1.792,0.648-3.062,0.932C44.979,46.293,45,46.64,45,47v1h-1    H28v-1h11l-9-2l1-3c0,0-2-3-8-3c-5,0-6,2-8,2H7v13h11l16.072,4.465c1.064,0.295,2.739,0.156,3.74-0.311L51,52l10-9    C62,42,60,37,55,40z"
              fill="#F4D0A1"
            />
          </g>
          <path
            d="M55,42c-3,2-5,4-7,5.027c-0.629,0.323-1.754,0.539-3,0.684V48h-1h-4.633C39.139,48.001,39,48,39,48H28v-1h8   h8c0-1-0.523-4-7.063-4h-6.955C28.85,42.127,26.68,41,23,41c-5,0-6,2-8,2H7v11h11l16.072,4.465c1.064,0.295,2.739,0.156,3.74-0.311   L51,52l10-9C62,42,61,38,55,42z"
            fill="#EDBC7C"
          />
          <path
            d="M43,46c0-1.656-1.344-3-3-3s-2,1.344-2,3c0,0.353,0.021,0.686,0.061,1h4.756C42.928,46.686,43,46.353,43,46   z"
            fill="#F6D9B4"
          />
          <g>
            <rect fill="#CFD8DC" height="15" width="3" x="12" y="40" />
            <rect fill="#B0BEC5" height="11" width="3" x="12" y="44" />
            <rect fill="#546E7A" height="19" width="9" x="3" y="39" />
            <rect fill="#455A64" height="14" width="9" x="3" y="44" />
          </g>
        </g>
      </svg>
    </div>
  );
}
