import { Link } from "react-router-dom";
import "./stylesMolde.css";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { useEffect } from "react";

export default function Molde({
  image,
  codigo,
  title,
  description,
  textButton,
  linkForm,
  requisitos,
  beneficios,
  condiciones,
}) {
  useEffect(() => {
    document.title = `DILE ${title}`;
  }, [title]);
  return (
    <>
    <FloatingWhatsApp 
            phoneNumber="959227256"
            accountName="Cooperativa DILE"
            avatar="/images/administradores_logo.png"
            chatMessage="Buenos días, le saluda la Cooperativa DILE"
            placeholder="escribenos un mensaje"
            statusMessage="Responde antes de 10 minutos"
            allowEsc
            allowClickAway
            notification
            notificationSound
           />
      <div className="mb-16">
        <div className="relative">
          <div
            className={`${codigo}-container`}
          ></div>

          <div className="absolute w-full h-full top-0 ">
            <div className="w-full h-full mx-auto max-w-7xl grid content-center px-4 md:px-8 lg:px-16">
              <h2 className="md:pr-8 pr-8 lg:pr-[600px] lg:pl-[10px]  md:pl-[10px] text-[40px] md:text-[50px] lg:text-[64px] font-bold text-primary-50 sm:text-primary-50">
                {title}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mb-28">
        <div className="w-full max-w-7xl mx-auto font-normal text-[18px] px-10 text-center ">
          <div className="lg:w-4/6 mx-auto ">
            <p className="text-[16px] lg:text-[24px] text-justify font-normal text-primary-800">
              {description}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full bg-primary-800">
        <div className="w-full h-full mx-auto max-w-7xl px-10">
          <div className="relative pt-8">
            <Link
              to={linkForm}
              className="absolute left-1/2 -ml-[150px] sm:-ml-[250px] md:-ml-[350px] lg:-ml-[450px] top-0 -mt-[50px]  shadow-custom  w-[300px] sm:w-[500px] md:w-[700px] lg:w-[900px] h-[100px] animate-bounce animate-infinite"
            >
              <button className="w-full h-full rounded-lg bg-secondary-400 font-bold text-primary-800 text-[16px] lg:text-[24px]">
                {textButton}
              </button>
            </Link>
            <div className="py-16">{requisitos}</div>
          </div>
        </div>
      </div>

      <div className="w-full bg-primary-50">
        <div className="w-full h-full mx-auto max-w-7xl px-10">
          <div className="py-16">{beneficios}</div>
        </div>
      </div>

      <div className="w-full bg-primary-800">
        <div className="w-full h-full mx-auto max-w-7xl px-10">
          <div className="py-16">{condiciones}</div>
        </div>
      </div>
    </>
  );
}
