import { Link } from "react-router-dom";
import "./styleMoldeBeneficio.css";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { useEffect } from "react";
import CardInfoHome from "./CardInfoServicios";

export default function MoldeBeneficio({
  image,
  title,
  linkForm,
  principal,
  servicios,
}) {
  useEffect(() => {
    document.title = `DILE ${title}`;
  }, [title]);
  return (
    <>
      <div className="mb-0">
        <div className="relative">
          <div
            className={`pagadiario-container`}
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}${image})`,
            }}
          ></div>

          <div className="absolute w-full h-full top-0 ">
            <div className="w-full h-full mx-auto max-w-7xl grid content-center px-16">
              <h2 className="text-[24px] md:text-[32px] lg:text-[64px] font-bold text-primary-50 smm:text-primary-50">
                {title}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <FloatingWhatsApp 
            phoneNumber="989629273"
            accountName="Mi CUMPA"
            chatMessage="Hola, soy CUMPA 🤝 Deseas agendar una cita?"
            placeholder="Agendemos tu cita"
            statusMessage="Responde antes de 10 minutos"
            allowEsc
            allowClickAway
            notification
            notificationSound
           />

      <div className="w-full bg-primary-50 ">
        <div className="w-full h-full mx-auto max-w-7xl px-10">
          <div className="py-8">{principal}</div>
        </div>
      </div>

      <div className="o_products_container_home">
        <div className="o_products_container-content">
          <div className="o_card2_title_home">
            <h2 className="">TE OFRECEMOS ESTOS SERVICIOS PROFESIONALES</h2>
          </div>
          <div className="o_card2_title_home">
            <h6 className="">Agenda una cita en una especialidad</h6>
          </div>
          <div className="w-full h-full grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-8 px-0 sm:px-8 my-12 flex-wrap mt-16">
            <CardInfoHome
              title={"TELEMEDICINA"}
              image={`${process.env.PUBLIC_URL}/images/medicina_micumpa.png`}
              link={"https://wa.link/ld3zc9"}
              output={false}
            />
            <CardInfoHome
              title={"ASESORÍA LEGAL"}
              image={`${process.env.PUBLIC_URL}/images/legal_micumpa.png`}
              link={"https://wa.link/devy93"}
              output={false}
            />
            <CardInfoHome
              title={"ASESORÍA CONTABLE"}
              image={`${process.env.PUBLIC_URL}/images/contabilidad_micumpa.png`}
              link={"https://wa.link/e0kgw1"}
              output={true}
            />
            <CardInfoHome
              title={"ASESORÍA NUTRICIONAL"}
              image={`${process.env.PUBLIC_URL}/images/nutricional_micumpa.png`}
              link={"https://wa.link/kdnimj"}
              output={true}
            />
            <CardInfoHome
              title={"ASESORÍA PSICOLÓGICA"}
              image={`${process.env.PUBLIC_URL}/images/psicologia_micumpa.png`}
              link={"https://wa.link/74wc6h"}
              output={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
